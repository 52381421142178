import React from "react"
import { graphql, navigate } from "gatsby"

/* Components */
import Layout from "../components/layout"
import Footer from "../components/footer/footer"
import StickyNav from "../components/sticky-nav/sticky-nav"
import { FaChevronLeft } from "react-icons/fa"

/* Styles */
import { returnButton } from "./betalingvoltooid.module.css"

const PaymentCompleted = ({ data, location }) => {
  const navigationDesks = data.navigationDesks.nodes

  const params = new URLSearchParams(location.search)
  const returnUrl = params.get("returnUrl")

  return (
    <Layout>
      <header className={"header"}>
        <StickyNav desks={navigationDesks} />
      </header>
      <main className="main" style={{ marginTop: "100px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            paddingTop: "250px",
            paddingBottom: "250px",
          }}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <h1>Bedankt voor uw betaling!</h1>
            <p style={{ fontSize: "20px" }}>
              Informatie over de betaling kunt u vinden in uw inbox.
            </p>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                width={200}
                fill="#0046eb"
              >
                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
              </svg>
            </div>
            {returnUrl && (
              <button
                className={returnButton}
                onClick={() => navigate(returnUrl)}
              >
                <FaChevronLeft
                  size={14}
                  color="white"
                  style={{ marginRight: "10px" }}
                />
                Terug
              </button>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}
export default PaymentCompleted

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    navigationDesks: allContentfulDesk(sort: { fields: sortNavigation }) {
      nodes {
        id
        name
        slug2
        sortNavigation
        ctaNavigation {
          image {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180)
          }
          title
          slug
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        deskcategory {
          id
          name
          slug2
          desk {
            id
            name
            slug2
          }
          article {
            id
            title
            slug2
            updateDate
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
        }
      }
    }
  }
`
